
.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}


.base{
  width:100%;
  display: grid;
  grid-template-columns: 1fr calc( var(--theme-screen-width) + 130px);
}

#card-app {
  background-color: #e3e3e3;
}
#card-app2 {
  background-color: #e3e3e3;
  max-width: 30rem;
}


#caracteres {
  font-size: 0.9rem;
}

:invalid {
  box-shadow: 0px 0px 3px red;
}


.sidebar{
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  /*box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);*/
}
.sidebar .sidebar-nav {
  position: absolute;
  background: white;
  height: 100vh ;
  padding: .25rem .25rem .25rem 40px;
}
.sidebar .sidebar-toggle{
  position: absolute;
  width:40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar .sidebar-nav, .sidebar .sidebar-toggle{
  height:100vh;
}
@media screen and (min-width: 992px) {
  .sidebar .sidebar-nav, .sidebar .sidebar-toggle{
    height: calc(100vh - 91px);
  }
  .sidebar .sidebar-nav{
    padding-left: .25rem;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
}

.sidebar .sidebar-toggle [aria-expanded=false] .expanded-content {
  display: none;
}
.sidebar .sidebar-toggle [aria-expanded=true] .collapsed-content {
  display: none;
}
