
.drop-zone{
  position: relative;
  min-height: 30vh;
  border: 1px solid var(--primary);
  border-radius: 4px;
}
.drop-zone.drag{
  opacity: 0.6;
}
.drop-zone.drag::before{
  content: "Déposer un fichier";
  color: white;
  opacity: 1;
  font-size: 3rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 40px;
  background-color: #10304088;
}

.drop-zone.medias-grid{
  padding: 10px;
  padding-right: 20px;
  grid-auto-rows: max-content;
}

