.custom-theme{
    color:var(--theme-color-default);
    font-size: var(--theme-fontsize-default);
    font-family: var(--theme-fontfamily-default), -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
}
.custom-theme h1{
    color: var(--theme-color-primary);
    font-size: var(--theme-fontsize-h1);
    font-family: var(--theme-fontfamily-h1), -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
}
.custom-theme h2{
    color:var(--theme-color-secondary);
    font-size: var(--theme-fontsize-h2);
    font-family: var(--theme-fontfamily-h2),-apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
}
.custom-theme .custom-btn{
    font-family: var(--theme-fontfamily-btn), -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
}