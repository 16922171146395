:root{
  --ipad-title: 42px;
}
.screen-container{
  box-sizing: content-box;
  margin: auto;
  border-radius: 4px;
  padding: 4px;
}


.screen-outline{
  /* logical ipad size is : 768 x 1024 */
  position:relative;
  box-sizing: content-box;
  border: 2px solid #222;
  margin: -4px;
  max-width: 100%;
  top: 0;
  height: 0;
}

.screen-container.ipad-screen{
  padding: 40px 40px 40px 80px;
}

.screen-container.ipad-screen .screen-outline{
  border: 40px solid #222;
  border-radius: 8px;
  border-left-width: 80px;
  margin: -40px -40px -40px -80px;
}

.screen-outline>.screen-title {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--light);
}

.screen-outline>.screen-content {
  position: absolute;
  bottom: 0;
  width:100%;
  overflow-y: auto;
}

.screen-container.ipad-screen .screen-outline::after{
  content:"O";
  display:block;
  position: absolute;
  margin-top:-30px;
  top: 50%;
  left:-50px;
  color: #aaa;
  font-weight: bold;
  font-size: 40px;
}

.screen-outline .background-image{
  width:100%;
  height:100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
