.card-grid{
  display: grid;
  padding: 10px;
  padding-right: 20px;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: max-content;
}

@media screen and (min-width: 1200px){
  .card-grid{
    grid-template-columns: repeat(4, minmax(150px, 1fr));
  }
}
.card-grid .card-wrap{
  position: relative;
  height: 0;
  padding-top: 100%;
  display: block;
}
.card-grid .card-wrap .card{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.card-grid .card-wrap .card .card-img-top{
  height: 56.25%;
}
.card-body .card-title {
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-text {
    font-style: italic;
    color : lightgrey;
    white-space: nowrap;
    overflow: hidden;
}

.card .card-img-top{
  object-fit: cover;
}

.horizontal-card{
  color: inherit;
}
.horizontal-card:hover{
  color: inherit;
}

.horizontal-card a:hover{
  color: var(--cyan);
}

.card-title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}