
.tooltip-container{
  position: relative;
  display: flex;
}
.tooltip-container .tooltip-icon{
  display: inline-flex;
  padding: 4px 5px;
}
.tooltip-container .tooltip-hover{
  position: absolute;
  display: none;
  top:50%;
  left:100%;
  z-index: 100;
  padding: .25rem;
  padding-left:.5rem;
  width: 350px;
  transform: translate(0, -50%);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}

.tooltip-container:hover .tooltip-hover {
  display: inline-block;
}

.tooltip-container .tooltip-hover .tooltip-arrow{
  position: absolute;
  width: 0;
  height: 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-style: solid;
  top: 50%;
  left: .5rem;
  margin-left: -5px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
}
.tooltip-container .tooltip-hover .tooltip-label {
  padding: .5rem;
  font-size: .9rem;
}
