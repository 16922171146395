.react-mde{
  flex-grow: 1;
  display:flex;
  flex-direction: column;
  justify-items: stretch;
  max-height: calc(100% - 20px);
}
textarea.mde-text {
  flex-grow: 1;
} 
.react-mde .grip{
  background: none;
}
.mde-preview .mde-preview-content h1{
  color: #e31937;
  font-size: 18px;
  border: none;
}
.mde-preview .mde-preview-content h2{
  color: #666666;
  font-size: 16px;
}
.markdown-edit-area{
  position: relative;
}
.markdown-edit-area .markdown-edit-text,
.markdown-edit-area .markdown-edit-clone{
  padding: 10px 4px;
  width: 100%;
  overflow: hidden;
  resize: none;
}

.markdown-edit-area .markdown-edit-clone {
  font-size: inherit;
  white-space: pre-wrap; 
  position: absolute;
  top: 0;
  left: -9999px; 
  height: auto;
}

.markdown-edit-area .btn:hover{
  opacity: 0.8;
}